@font-face {
    font-family: Axiforma;
    font-weight: 600;
    font-style: normal;
    src: url('./Axiforma-SemiBold.eot');
    src: url('./Axiforma-SemiBold.eot?#iefix') format('embedded-opentype'),
      url('./Axiforma-SemiBold.woff') format('woff'),
      url('./Axiforma-SemiBold.ttf') format('truetype');
    font-display: fallback;
  }

@font-face {
    font-family: Axiforma;
    font-weight: 500;
    font-style: normal;
    src: url('./Axiforma-Medium.ttf');
    src: url('./Axiforma-Medium.eot?#iefix') format('embedded-opentype'),
      url('./Axiforma-Medium.woff') format('woff'),
      url('./Axiforma-Medium.ttf') format('truetype');
    font-display: fallback;
}

@font-face {
    font-family: Axiforma;
    font-weight: normal;
    font-style: normal;
    src: url('./Axiforma-Regular.ttf');
    src: url('./Axiforma-Regular.eot?#iefix') format('embedded-opentype'),
      url('./Axiforma-Regular.woff') format('woff'),
      url('./Axiforma-Regular.ttf') format('truetype');
    font-display: fallback;
}


@font-face {
  font-family: Axiforma;
  font-weight: 300;
  font-style: normal;
  src: url('./Axiforma-Light.ttf');
  src: url('./Axiforma-Light.eot?#iefix') format('embedded-opentype'),
    url('./Axiforma-Light.woff') format('woff'),
    url('./Axiforma-Light.ttf') format('truetype');
  font-display: fallback;
  }