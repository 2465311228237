* {
  scroll-behavior: smooth;
}

.en {
  font-family: Axiforma;
  font-size: 14px;
  line-height: 1.79;
}

.container-fluid {
  padding-left: 100px;
  padding-right: 100px;
}

@media (max-width: 992px) {
  .container-fluid {
    padding-left: 24px;
    padding-right: 24px;
  }
}

.text-secondary {
  color: #b9bec7 !important;
}

.dot-background {
  position: relative;
  z-index: 1;
}

.dot-background:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='%23333' class='bi bi-circle-fill' viewBox='0 0 24 24'><circle fill='%23e4ebf2' cx='2' cy='2' r='2'/></svg>");
  z-index: -1;
}

.dot-background.op-50:after {
  opacity: 0.5;
}

.dot-background.op-20:after {
  opacity: 0.2;
}

@media (max-width: 768px) {
  .dot-background:after {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='14' height='14' fill='%23333' class='bi bi-circle-fill' viewBox='0 0 14 14'><circle fill='%23e4ebf2' cx='1' cy='1' r='1'/></svg>");
  }
}

.gradient-bg-default {
  background-image: linear-gradient(128deg, #1B90FA, #84C0F5 100%);
}

.en-font {
  font-family: Axiforma;
}

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
  background-color: rgba(39, 40, 44, 0.4);
}

.backdrop.fade {
  opacity: 0;
  z-index: 1050;
  transition: opacity .15s linear;
}

.backdrop.show {
  opacity: 1;
}
.btn {
  padding: 13px 40px 11px;
  border-radius:  10px;
}

.btn-primary {
  font-weight: 600;
  color: #fff;
  background-color: transparent;
  border: none;
  background: linear-gradient(128deg, #1B90FA, #84C0F5 100%);
  box-shadow: 0 8px 20px 0 rgba(27, 144, 250, 0.24);
}

.btn-primary:hover {
  color: #fff;
  background-color: #0069d9;
}

.btn-primary:focus, .btn-primary.focus {
  color: #fff;
  background-color: #0069d9;
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #007bff;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0062cc;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

.cover-parent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.owl-carousel .owl-item img{
  width: auto;
}