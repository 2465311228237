@import url('./assets/fonts/Yekan-Bakh/Yekan-Bakh-Fa-En.css');
@import url('./assets/fonts/Yekan-Bakh/Yekan-Bakh-FaNum.css');
@import url('./assets/fonts/Axiforma/Axiforma.css');

:root {
  --dark: #27282c;
  --muted: #b9bec7;
}

body {
  margin: 0;
  font-family: Yekan-Bakh-Fa-En, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.56;
  color: var(--dark);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

a {
  text-decoration: none;
  outline: none;
  color: #27282c;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

a:hover {
  color: #1b90fa;
  text-decoration: none;
}

p {
  margin: 0;
}

h1 {
  font-size: 64px;
  line-height: 1.55;
  font-weight: 700;
}

h2 {
  font-family: Yekan-Bakh-FaNum;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.5;
}

h2.en {
  font-family: Axiforma;
  font-size: 42px;
  font-weight: bold;
  line-height: 1.83;
}

h3 {
  font-family: Yekan-Bakh-FaNum;
  font-size: 32px;
  font-weight: 500;
  line-height: 1.56;
}