@font-face {
  font-family: Yekan-Bakh-FaNum;
  font-weight: 800;
  font-style: normal;
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 08\ Fat.eot');
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 08\ Fat.eot?#iefix') format('embedded-opentype'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 08\ Fat.woff') format('woff'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 08\ Fat.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: Yekan-Bakh-FaNum;
  font-weight: 700;
  font-style: normal;
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 07\ Heavy.eot');
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 07\ Heavy.eot?#iefix') format('embedded-opentype'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 07\ Heavy.woff') format('woff'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 07\ Heavy.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: Yekan-Bakh-FaNum;
  font-weight: 600;
  font-style: normal;
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 06\ Bold.eot');
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 06\ Bold.eot?#iefix') format('embedded-opentype'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 06\ Bold.woff') format('woff'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 06\ Bold.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: Yekan-Bakh-FaNum;
  font-weight: normal;
  font-style: 500;
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 05\ Medium.eot');
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 05\ Medium.eot?#iefix') format('embedded-opentype'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 05\ Medium.woff') format('woff'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 05\ Medium.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: Yekan-Bakh-FaNum;
  font-weight: 400;
  font-style: normal;
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 04\ Regular.eot');
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 04\ Regular.eot?#iefix') format('embedded-opentype'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 04\ Regular.woff') format('woff'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 04\ Regular.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: Yekan-Bakh-FaNum;
  font-weight: 300;
  font-style: normal;
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 03\ Light.eot');
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 03\ Light.eot?#iefix') format('embedded-opentype'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 03\ Light.woff') format('woff'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 03\ Light.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: Yekan-Bakh-FaNum;
  font-weight: 200;
  font-style: normal;
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 02\ Thin.eot');
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 02\ Thin.eot?#iefix') format('embedded-opentype'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 02\ Thin.woff') format('woff'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 02\ Thin.ttf') format('truetype');
  font-display: fallback;
}

@font-face {
  font-family: Yekan-Bakh-FaNum;
  font-weight: 100;
  font-style: normal;
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 01\ Hairline.eot');
  src: url('./FaNum/Yekan\ Bakh\ FaNum\ 01\ Hairline.eot?#iefix') format('embedded-opentype'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 01\ Hairline.woff') format('woff'),
    url('./FaNum/Yekan\ Bakh\ FaNum\ 01\ Hairline.ttf') format('truetype');
  font-display: fallback;
}
